import React from "react"
import { Link } from "gatsby"
import ImageMeta from "../../components/ImageMeta"
import Button from "@bit/azheng.joshua-tree.button"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

const LearnMoreCards = ({ post }) => {
  if (!post.hasThisSection) return ""

  const cards = post.cards

  return (
    <div className="homepage-gray-wrapper">
      <div className="featured-procedure-margin column">
        <h2 className="has-text-centered-tablet h3-style">{post.heading}</h2>
        <MarkdownViewer markdown={post.blurb} />
        <div className="featured-procedures-2-2">
          {cards.map((card, i) => {
            return (
              <ul key={i}>
                <li>
                  <ImageMeta
                    cloudName="nuvolum"
                    loading="lazy"
                    publicId={card.imageId}
                    width="auto"
                    responsive
                  ></ImageMeta>
                </li>
                <li>
                  <h2 className="h3-style">{card.heading}</h2>
                  <MarkdownViewer markdown={card.blurb} />
                  <Link
                    className="standard-button contained"
                    to={card.button.href}
                  >
                    {card.button.buttonText}
                  </Link>
                </li>
              </ul>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default LearnMoreCards
