import React from "react"
import CenterColumn from "../../components/Bulma/CenterColumn"
import ImageMeta from "../../components/ImageMeta"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"
import { Link } from "gatsby"

function StemodonticsBanner() {
  const LOGO_PUBLIC_ID = "SDOS/DEV/stemodontics-banner-logo"
  const STEM_CELLS_PROCEDURE_LINK =
    "/oral-surgery-procedures/wisdom-teeth-removal-san-diego-ca/stem-cells-from-wisdom-teeth/"

  return (
    <CenterColumn
      className="stemodontics-banner"
      leftColWidth={0}
      rightColWidth={0}
      centerColumn={
        <div className="column stemo-logo">
          <ImageMeta
            data-aos="fade-right"
            data-aos-duration="1200"
            cloudName="nuvolum"
            publicId={LOGO_PUBLIC_ID}
            responsive
          />
          <div data-aos="fade-left" data-aos-duration="1200">
            <MarkdownViewer
              markdown={
                "### Stem Cell Banking Available!\n\nLearn more about how to invest in your future health by banking wisdom teeth stem cells with Stemodontics."
              }
            />
            <div className="button-row">
              <Link to={STEM_CELLS_PROCEDURE_LINK}>Learn More</Link>
            </div>
          </div>
        </div>
      }
    />
  )
}

export default StemodonticsBanner
