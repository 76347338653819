import React, { useState } from "react"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

import "./ProgramToast.scss"

const ProgramToast = ({ closeDate, children }) => {
  if (!children) return null

  const [close, setClose] = useState(false)

  const today = new Date()
  const end = new Date(closeDate)

  if (close || today > end) return null

  return (
    <div className="program-toast">
      <span
        role="button"
        aria-label="close banner"
        tabIndex="0"
        className="close-toast"
        onClick={() => setClose(true)}
      >
        ✖
      </span>
      <MarkdownViewer markdown={children} />
    </div>
  )
}

export default ProgramToast
